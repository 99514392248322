<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="推广人名称" prop="referrerName" >
        <a-input v-model="form.referrerName" placeholder="请输入推广人名称" />
      </a-form-model-item>
      <a-form-model-item label="推广人手机号" prop="referrerMobile" >
        <a-input :maxLength="11" v-model="form.referrerMobile" placeholder="请输入推广人手机号" />
      </a-form-model-item>
      <a-form-model-item label="推广链接" prop="referrerUrl" v-if="form.referrerUrl">
        <a-input readOnly v-model="form.referrerUrl" placeholder="请输入推广链接" />
      </a-form-model-item>
      <a-form-model-item label="二维码" prop="referrerImage" v-if="form.referrerUrl">
        <img v-viewer  style="width: 48px;height: 48px;margin-top: 5px;margin-right: 5px;" v-if="form.referrerImage"
             :src="form.referrerImage" preview="头像"/>
      </a-form-model-item>
      <a-form-model-item label="投放金额" prop="investedAmount" >
        <a-input-number :min="0" style="width: 300px" v-model="form.investedAmount" placeholder="请输入投放金额" />
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status" >
        <a-radio-group v-model="form.status">
          <a-radio :value="0">禁用</a-radio>
          <a-radio :value="1">启用</a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getReferrer, addReferrer, updateReferrer } from '@/api/referrer/referrer'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        referrerName: null,
        investedAmount:0,
        status:null,
        referrerMobile: null,

        referrerUrl: null,
        referrerImage:null,
        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        referrerName: [
          { required: true, message: '推广人名称不能为空', trigger: 'blur' }
        ],
        referrerMobile: [
          { required: true, message: '推广人手机号不能为空', trigger: 'blur' }
        ],
        referrerUrl: [
          { required: true, message: '推广链接不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态不能为空', trigger: 'change' }
        ],
        investedAmount: [
          { required: true, message: '投放金额不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.reset()
      this.open = false
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        referrerName: null,
        referrerMobile: null,
        referrerUrl: null,
        createTime: null,
        referrerImage:null,
        investedAmount:0,
        status:null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.open = true
      this.reset()
      this.formType = 1
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.open = true
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getReferrer({"id":id}).then(response => {
        this.form = response.data

        this.formTitle = '修改'
      })
    },

    checkMobile(mobile){
      let phoneRegex = /^1[34578]\d{9}$/;
      if (phoneRegex.test(mobile)) {
        console.log('手机号格式正确');
        return true;
      } else {
        console.log('手机号格式错误');
        return false;
      }
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if(!this.checkMobile(this.form.referrerMobile)){
            this.$message.error("手机号格式不正确")
            return
          }
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateReferrer(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addReferrer(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
