import request from '@/utils/request'


// 查询推广人列表
export function listReferrer(query) {
  return request({
    url: '/referrer/referrer/list',
    method: 'get',
    params: query
  })
}

// 查询推广人分页
export function pageReferrer(query) {
  return request({
    url: '/referrer/referrer/page',
    method: 'get',
    params: query
  })
}

// 查询推广人详细
export function getReferrer(data) {
  return request({
    url: '/referrer/referrer/detail',
    method: 'get',
    params: data
  })
}

// 新增推广人
export function addReferrer(data) {
  return request({
    url: '/referrer/referrer/add',
    method: 'post',
    data: data
  })
}

// 修改推广人
export function updateReferrer(data) {
  return request({
    url: '/referrer/referrer/edit',
    method: 'post',
    data: data
  })
}

// 删除推广人
export function delReferrer(data) {
  return request({
    url: '/referrer/referrer/delete',
    method: 'post',
    data: data
  })
}
